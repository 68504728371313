<template>
    <b-card class="mb-2 input-group-alternative" :class="{'shadow': hover && shadow}" @mouseover="hover = true;" @mouseleave="hover = false;">
        <template v-slot:header>
            <h5 class="mb-0" style="font-size: small; font-weight: bold; text-transform:uppercase">
                <span v-if="type == 'reply'">
                    {{$t('reply.request.property.type.' + property.type)}} {{$t('reply.request.property.' + property.property)}}
                </span>
                <span v-if="type == 'request'">
                    {{$t('property.you.' + property.type)}} {{$t('reply.request.property.' + property.property)}}
                </span>
            </h5>
        </template>
        <b-card-title style="font-size:large;">
            Em {{property.location.slice(0, property.location.indexOf(',', property.location.indexOf('-')))}}
        </b-card-title>
        <b-card-text>
            <div>
                <div v-if="property.amount[0] > 0" class="mt-2 justify-content-md-center">
                    <span class="col-md-auto lead" style="font-weight: bold;">{{$t('property.amount', {minamount: property.amount[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'), maxamount: property.amount[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')})}}</span>
                </div>
                <span class="col-md-auto lead" style="font-weight: bold;" v-else>{{$t('property.upto', {amount: property.amount[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')})}}</span>
            </div>
            <div v-if="!property.area.includes(15) && !property.area.includes(650)" class="mt-2 justify-content-md-center">
                <span class="col-md-auto lead" style="font-weight: bold;">{{$t('property.area', {minarea: property.area[0], maxarea: property.area[1]})}}</span>
            </div>
            <!--<div v-if="property.rent_time > 0" class="mt-2 row justify-content-md-center">
                <span class="col-md-auto">{{$tc('response.time', property.rent_time, {quantity: property.rent_time})}}</span>
            </div>-->
            <div class="mt-3 justify-content-md-center">
                <!--<badge class="goodge" v-if="property.floor > 0" type="primary">
                    <i class="fa fa-building" aria-hidden="true"></i>
                    {{$tc('response.floor', property.floor, { quantity: parseInt(property.floor) })}}
                </badge>-->
                <badge class="goodge" v-if="property.suite > 0" type="primary">
                    <i class="fa fa-bath" aria-hidden="true"></i>
                    {{$tc('response.suite', property.suite, { quantity: parseInt(property.suite) })}}
                </badge>
                <badge class="goodge" v-if="property.room > 0" type="primary">
                    <i class="fa fa-bed" aria-hidden="true"></i>
                    {{$tc('response.room', property.room, { quantity: parseInt(property.room) })}}
                </badge>
                <badge class="goodge" v-if="property.garage > 0" type="primary">
                    <i class="fa fa-car" aria-hidden="true"></i>
                    {{$tc('response.garage', property.garage, { quantity: parseInt(property.garage) })}}
                </badge>
                <badge class="goodge" v-if="property.furnished" type="primary">
                    <i class="fa fa-television" aria-hidden="true"></i>
                    {{$t('response.furnished')}}
                </badge>
                <badge class="goodge" v-if="property.pet != '0'" type="primary">
                    <i class="fa fa-paw" aria-hidden="true"></i>
                    {{$tc('response.pet', property.pet)}}
                </badge>
            </div>
        </b-card-text>
        <template v-slot:footer>
            <div v-if="property.feature.length > 1">
                <div class="row">
                    <small class="col colText">{{$t('response.feature')}}</small>
                </div>
                <div class="row">
                    <div class="col colText">
                        <span v-for="(feat, n) in property.feature" :key="n">
                            {{feat}}{{n + 1 == property.feature.length ? '.' : ','}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="property.detail">
                <div class="row">
                    <div class="col colText">
                        <i class="fa fa-quote-left fa-1" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="row">
                    <div class="col colText">
                        {{property.detail}}
                    </div>
                </div>
            </div>
            <div v-if="type == 'reply'">
                <router-link v-if="$store.state.logged_in" :to="'/reply/'+property._id">
                    <base-button class="mt-3" block type="primary" @click="$gtag.event('go_reply')">
                        {{$t('property.reply')}}
                    </base-button>
                </router-link>
                <base-button v-else class="mt-3" type="primary" @click="$emit('getPropertyId', property._id); $gtag.event('realtor_see_register_card'); $gtag.event('go_reply')">
                    {{$t('property.reply')}}
                </base-button>
                <div class="mt-3">
                    <small>
                        <i>
                            <div v-if="property.static">{{$t('reply.request.warning.static')}}</div>
                            <div v-else>{{$t('reply.request.warning.dynamic')}}</div>
                        </i>
                    </small>
                </div>
            </div>
            <div v-if="type == 'request' && property.status == 'inactive'">
                <base-button tag="a" :href="preferences[0]" textColor="white" target="_blank" block class="mt-3" type="success" @click="$gtag.event('activate_request')">
                    {{$t('response.request.button')}}
                </base-button>
                <div class="mt-3">
                    <small>
                        <i>{{$t('response.request.warning')}}</i>
                    </small>
                </div>
            </div>
        </template>
    </b-card>
</template>
<script>
    import BaseButton from '../components/BaseButton'
    import {
        BCard,
        BCardText,
        BCardTitle,
    } from 'bootstrap-vue'
    import $ from 'jquery';
    export default {
        name: "property-card",
        components: {
            BaseButton,
            BCard,
            BCardText,
            BCardTitle,
        },
        props: {
            property: {
                type: Object,
                description: "Request data",
            },
            shadow: { default: true },
            type: { default: 'reply' },
            request_id: {},
        },
        data() {
            return {
                hover: false,
                preferences: [''],
            }
        },
        mounted() {
            this.$gtag.set({ 'user_id': this.$store.state._id });
            if (this.type == 'request' && this.request_id) {
                var data = this.$data;
                $.ajax({
                    url: 'https://api.immobl.com/payment/' + this.request_id,
                    type: 'GET',
                    xhrFields: {
                        withCredentials: true
                    },
                    crossDomain: true,
                    success: function (response) {
                        if (response.success) {
                            data.preferences = response.preferences;
                        }
                    },
                    //error: function (err) {
                    //console.log(err);
                    //}
                });
            }
        }
    };
</script>
<style scoped>
    .colText {
        text-align: left;
    }

    .goodge {
        font-size: 13.5px;
        font-weight: lighter;
        margin: 2px;
    }
</style>