<template>
    <div>
        <section class="my-0" :class="{'section': desktop}">
            <card class="border-0">
                <div class="row justify-content-md-center">
                    <div class="col-lg-6">
                        <card type="secondary" shadow
                              header-classes="bg-white pb-5"
                              body-classes="px-lg-5 py-lg-5"
                              class="border-0">
                            <template>
                                <div v-for="property in properties" :key="property._id">
                                    <div if="type == 'requestor' && responses.length == 0">
                                        <p v-if="property.status == 'inactive'" style="text-align: left; font-size: 17px; font-weight:400; line-height:normal !important">
                                            {{$t('response.request.call')}}
                                        </p>
                                        <base-alert v-else shadow type="primary">
                                            <span>
                                                {{$t('notification.response')}}.
                                            </span>
                                        </base-alert>
                                    </div>

                                    <property-card :property="property"
                                                   :shadow="false"
                                                   type="request"
                                                   :request_id="$route.params.response_id"></property-card>
                                </div>

                                <div v-if="responses.length > 0" :class="{'mt-5': type == 'requestor'}">
                                    <div v-if="type == 'realtor'">
                                        <base-alert v-if="responses[0].reaction == 'standby'" type="primary">
                                            <i18n path="response.reaction.realtor.standby" tag="span" class="alert-inner--text">
                                                <template v-slot:name>
                                                    <router-link style="color: white; font-weight: bold;" :to="'/profile/'+ responses[0].to._id"><span @click="$gtag.event('see_requestor_standby')">{{responses[0].to.name}}</span></router-link>
                                                </template>
                                            </i18n>
                                        </base-alert>
                                        <base-alert v-if="responses[0].reaction == 'accept'" type="success">
                                            <i18n path="response.reaction.realtor.accept" tag="span" class="alert-inner--text">
                                                <template v-slot:name>
                                                    <router-link style="color: white; font-weight: bold;" :to="'/profile/'+ responses[0].to._id"><span @click="$gtag.event('see_requestor_accept')">{{responses[0].to.name}}</span></router-link>
                                                </template>
                                                <template v-slot:contact>
                                                    <a style="color: white; font-weight: bold; cursor:pointer;" @click="contactRequestor(responses[0].to._id, 'requestor'); $gtag.event('see_contact')">{{$t('response.reaction.realtor.contact')}}</a>
                                                </template>
                                            </i18n>
                                        </base-alert>
                                        <base-alert v-if="responses[0].reaction == 'reject'" type="danger">
                                            <i18n path="response.reaction.realtor.reject" tag="span" class="alert-inner--text">
                                                <template v-slot:name>
                                                    <router-link style="color: white; font-weight: bold;" :to="'/profile/'+ responses[0].to._id"><span @click="$gtag.event('see_requestor_reject')">{{responses[0].to.name}}</span></router-link>
                                                </template>
                                            </i18n>
                                        </base-alert>
                                    </div>

                                    <div v-for="(property, n) in responses" :key="property._id">
                                        <div v-if="type == 'requestor'">
                                            <base-alert v-if="property.reaction == 'standby'" type="primary">
                                                <i18n path="response.reaction.requestor.standby" tag="span" class="alert-inner--text">
                                                    <template v-slot:name>
                                                        <router-link style="color: white; font-weight: bold;" :to="'/profile/'+ property.from._id"><span @click="$gtag.event('see_realtor_standby')">{{property.from.name}}</span></router-link>
                                                    </template>
                                                </i18n>
                                            </base-alert>
                                            <base-alert v-else-if="property.reaction == 'accept'" type="success">
                                                <i18n path="response.reaction.requestor.accept" tag="span" class="alert-inner--text">
                                                    <template v-slot:name>
                                                        <router-link style="color: white; font-weight: bold;" :to="'/profile/'+ property.from._id"><span @click="$gtag.event('see_realtor_accept')">{{property.from.name}}</span></router-link>
                                                    </template>
                                                </i18n>
                                            </base-alert>
                                            <base-alert v-else-if="property.reaction == 'reject'" type="danger">
                                                <i18n path="response.reaction.requestor.reject" tag="span" class="alert-inner--text">
                                                    <template v-slot:name>
                                                        <router-link style="color: white; font-weight: bold;" :to="'/profile/'+ property.from._id"><span @click="$gtag.event('see_realtor_reject')">{{property.from.name}}</span></router-link>
                                                    </template>
                                                </i18n>
                                            </base-alert>
                                        </div>

                                        <response-card :property="property"
                                                       :type="type"></response-card>
                                        <div v-if="n + 1 < responses.length" class="mt-5"></div>
                                    </div>
                                </div>
                            </template>
                        </card>
                    </div>
                </div>
            </card>
            <modal :show.sync="modal"
                   gradient="success"
                   modal-classes="modal-danger modal-dialog-centered">
                <h6 slot="header" class="modal-title" id="modal-title-notification">{{$t('response.contact.info')}}</h6>

                <div class="py-3 text-center">
                    <i class="ni ni-chat-round ni-4x"></i>
                    <p class="mt-4">{{$t('response.contact.interest', {name: contact.name.full})}}</p>
                    <i18n path="response.contact.contact" tag="p">
                        <template v-slot:phone>
                            <a style="color: white; font-weight: bold;" target="_blank" @click="$gtag.event('contact_number')" :href="(desktop ? 'https://web' : 'https://api') + '.whatsapp.com/send?phone=' + contact.number.international">{{contact.number.international}}</a>
                        </template>
                        <template v-slot:email>
                            <a style="color: white; font-weight: bold;" @click="$gtag.event('contact_email')" :href="'mailto:' + contact.email">{{contact.email}}</a>
                        </template>
                    </i18n>
                </div>
            </modal>
        </section>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="true"
                     loader="dots"
                     :is-full-page="true"
                     :opacity="1"
                     color="#5271ff"></loading>
        </div>
    </div>
</template>

<script>
    import ResponseCard from '../components/ResponseCard';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import $ from 'jquery';
    import PropertyCard from '../components/PropertyCard';

    export default {
        components: {
            ResponseCard,
            Loading,
            PropertyCard
        },

        name: 'Response',

        data() {
            return {
                responses: [],
                type: this.$store.state.type,
                properties: [],
                contact: {
                    name: {},
                    email: '',
                    number: {}
                },
                modal: false,
                isLoading: true,
                desktop: screen.width > 750,
            }
        },

        methods: {
            updateResponses(documents) {
                this.responses = documents;
            },
            updateInput(key, value) {
                this.$data[key] = value;
            },
            getInput(key) {
                return this.model[key]
            },
            wipeOnBlur(event) {
                event.target.value = '';
            },
            ranger(value) {
                var tag = ' months'
                if (value == 1) {
                    tag = ' month'
                } else if (value == 12) {
                    tag = ' year'
                    value = 1
                }
                return value + tag;
            },
            openContactModal() {
                this.modal = true;
            },

            contactRequestor(_id, type) {
                var openContactModal = this.openContactModal;
                var updateInput = this.updateInput;

                if ($.isEmptyObject(this.contact.name)) {
                    $.ajax({
                        url: 'https://api.immobl.com/contact/' + _id + '?type=' + type,
                        contentType: 'application/json;charset=UTF-8',
                        xhrFields: {
                            withCredentials: true
                        },
                        crossDomain: true,
                        type: 'GET',
                        success: function (resp) {
                            updateInput('contact', resp.document);
                        },
                        //error: function (err) {
                        //    //console.log(err);
                        //}
                    });
                }

                openContactModal();
            },

            updateProperty(request) {                
                if (this.type == 'requestor' && request.from == this.$store.state._id) {
                    this.$data.properties.push(request);
                } else {
                    this.$router.push('/dashboard');
                }
            }
        },

        mounted() {
            this.$gtag.set({ 'user_id': this.$store.state._id });
            var updateResponses = this.updateResponses;
            var data = this.$data;
            const router = this.$router;
            var updateProperty = this.updateProperty;

            if (this.type == 'requestor') {
                $.ajax({
                    url: 'https://api.immobl.com/request/' + this.$route.params.response_id,
                    contentType: 'application/json;charset=UTF-8',
                    xhrFields: {
                        withCredentials: true
                    },
                    crossDomain: true,
                    type: 'GET',
                    success: function (response) {
                        if (response.success) {
                            updateProperty(response.document);
                        } else {
                            router.push('/dashboard');
                        }
                    },
                    //error: function (err) {
                    //    //console.log(err);
                    //}
                });
            }

            $.ajax({
                url: 'https://api.immobl.com/response/' + this.$route.params.response_id,
                contentType: 'application/json;charset=UTF-8',
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                type: 'GET',
                success: function (resp) {
                    if (resp.success) {
                        updateResponses(resp.responses);
                        data.isLoading = false;
                    } else {
                        router.push('/dashboard')
                    }
                },
                //error: function (err) {
                //    //console.log(err);
                //}
            });

        },

    };
</script>

<style scoped>
    .Gmap {
        width: 65%;
        height: 650px;
        float: left;
        /*bottom: 0;*/
        position: absolute;
    }

    .properties {
        width: 35%;
        float: right;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 650px;
        bottom: 0;
        position: relative;
        margin-bottom: 0px;
    }

    .form {
        width: 50%;
        margin-left: 6px;
    }

    img {
        max-width: 70px;
        float: left;
    }
</style>
